import React, { useState, useEffect } from 'react';
import { Row, Col, Modal, Button, IconButton, FlexboxGrid } from 'rsuite';
import axios from 'axios';
import { ParsePlanCard } from '../components/pages/ParsePlanPage/ParsePlanCard';
import PlusIcon from '@rsuite/icons/Plus';

//page display parse plan list
export const ParsePlanPage = () => {
	const baseUrl = '/api/parse-plans';
	const [backendUrl, setBackendUrl] = useState(baseUrl);
	const [parsedPlansArr, setParsedPlansArr] = useState([]);

	//modal configuration
	const [modalOpen, setModalOpen] = React.useState(false);
	const [modalBackdrop, setModalBackdrop] = React.useState('static');
	const [modalText, setModalText] = React.useState('');
	const [modalTitle, setModalTitle] = React.useState('');

	const handleClose = () => setModalOpen(false);
	const handleModalOk = () => {};

	const getParsedPlans = () => {
		axios.get(backendUrl).then((res) => {
			const { data, paging } = res.data;
			setParsedPlansArr(data);
		});
	};

	useEffect(() => {
		getParsedPlans();
	}, [getParsedPlans]);

	//handle data
	const handleClick = (action, parsePlanId) => {
		switch (action) {
			case 'delete':
				if (window.confirm('Delete Selected Item ?')) {
					// Save it!
					axios
						.delete(`${baseUrl}/${parsePlanId}`)
						.then((res) => {
							getParsedPlans();
							console.warn('item deleted');
						})
						.catch((e) => {
							console.log(e);
						});
				}

				break;
			case 'edit':
				console.log('edit action');
				break;
			case 'copy':
				if (window.confirm('Copy Selected Item ?')) {
				}
				break;
			default:
				break;
		}
	};

	return (
		<>
			<FlexboxGrid justify='space-between' align='middle'>
				<FlexboxGrid.Item>
					<h5>Parsing plan page</h5>
					<p>
						<small> Config parsing plan </small>
					</p>
				</FlexboxGrid.Item>
				<FlexboxGrid.Item>
					<IconButton color='green' appearance='primary' icon={<PlusIcon />}>
						Add
					</IconButton>
				</FlexboxGrid.Item>
			</FlexboxGrid>

			<Row>
				{parsedPlansArr.map((elem, index) => {
					return (
						<Col key={index} md={6} sm={12}>
							<ParsePlanCard parsePlanItem={elem} handleClick={handleClick} />
						</Col>
					);
				})}
			</Row>

			<Modal
				backdrop={modalBackdrop}
				keyboard={false}
				open={modalOpen}
				onClose={handleClose}
			>
				<Modal.Header>
					<Modal.Title>{modalTitle}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<p>{modalText}</p>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={handleModalOk} appearance='primary'>
						Ok
					</Button>
					<Button onClick={handleClose} appearance='subtle'>
						Cancel
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};
