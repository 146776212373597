import React, { useState } from 'react';
import {
	Grid,
	Panel,
	Row,
	Col,
	FlexboxGrid,
	IconButton,
	ButtonToolbar,
} from 'rsuite';

import CopyIcon from '@rsuite/icons/Copy';
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';

export const ParsePlanCard = ({ parsePlanItem, handleClick }) => {
	const [parsePlan, setParsePlan] = useState(parsePlanItem);

	return (
		<Panel shaded bordered bodyFill>
			<Panel header={`Site:  ${parsePlan.site.name}`}>
				<Grid fluid>
					<Row>
						<Col xs={12}>
							<b>Parse Compleated</b>
						</Col>
						<Col xs={12}> {parsePlan.parseCompleted ? 'Yes' : 'No'} </Col>
					</Row>
					<Row>
						<Col xs={12}>
							<b>Parse Start:</b>
						</Col>
						<Col xs={12}> {new Date(parsePlan.parseStart).toLocaleDateString()} </Col>
					</Row>
					<Row>
						<Col xs={12}>
							<b>Parse End:</b>
						</Col>
						<Col xs={12}> {new Date(parsePlan.parseEnd).toLocaleDateString()} </Col>
					</Row>
					<Row>
						<Col xs={12}>
							<b>Parse Step:</b>
						</Col>
						<Col xs={12}>{parsePlan.parseStep} </Col>
					</Row>
					<Row>
						<Col xs={12}>
							<b>Site lastUpdate:</b>
						</Col>
						<Col xs={12}>
							{new Date(parsePlan.site.lastUpdate).toLocaleDateString()}
						</Col>
					</Row>
				</Grid>
			</Panel>

			<FlexboxGrid align='middle' justify='center'>
				<FlexboxGrid.Item>
					<ButtonToolbar>
						<IconButton
							onClick={() => handleClick('delete', parsePlan._id)}
							icon={<TrashIcon />}
						/>
						<IconButton
							onClick={() => handleClick('edit', parsePlan._id)}
							icon={<EditIcon />}
						/>
						<IconButton
							onClick={() => handleClick('copy', parsePlan._id)}
							icon={<CopyIcon />}
						/>
					</ButtonToolbar>
				</FlexboxGrid.Item>
			</FlexboxGrid>
		</Panel>
	);
};
