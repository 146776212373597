import React, { useState, useEffect } from 'react';
import { Table } from 'rsuite';
import './ProductResultList.css';

const { Column, HeaderCell, Cell } = Table;

export const ProductResultList = ({ productsList }) => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [products, setProducts] = useState([]);
	const [columnsKeys, setColumnsKeys] = useState([]);
	const productLabel = 'Продукт';

	useEffect(() => {
		const mappedData = productsList.map((product, i) => {
			if (product.offers) {
				for (let i in product.offers) {
					product[product.offers[i].date] = product.offers[i].price;
				}
				delete product.offers;
			}
			return product;
		});

		setColumnsKeys(Object.keys(mappedData[0]));
		setProducts(mappedData);
	}, [productsList]);

	return (
		<div className='result-table_wrapper'>
			<Table
				autoHeight
				affixHeader
				className='result-table'
				data={products}
				renderEmpty={() => {
					return <div className='rs-table-body-info'>No data found </div>;
				}}
			>
				{columnsKeys.map((data_key, index) => {
					switch (data_key) {
						case 'id':
							return (
								<Column key={index} width={100} align='center' fixed>
									<HeaderCell>Id</HeaderCell>
									<Cell dataKey={data_key} />
								</Column>
							);

						case 'name':
							return (
								<Column key={index} minWidth={300} flexGrow={1}>
									<HeaderCell>{productLabel}</HeaderCell>
									<Cell dataKey={data_key}>
										{(rowData) => (
											<a target='_blank' rel='noopener noreferrer' href={rowData.url}>
												{rowData.name}
											</a>
										)}
									</Cell>
								</Column>
							);
						case 'url':
							return (
								<Column key={index}>
									<HeaderCell>Сайт</HeaderCell>
									<Cell dataKey={data_key} width={100}>
										{(rowData) => {
											let domain = new URL(rowData.url);
											return domain.hostname;
										}}
									</Cell>
								</Column>
							);
						default:
							return (
								<Column key={index} width={90} align='center'>
									<HeaderCell>{data_key}</HeaderCell>
									<Cell dataKey={data_key} />
								</Column>
							);
					}
				})}
			</Table>
		</div>
	);
};
