import React, { useEffect, useState } from 'react';
import {
	Form,
	Input,
	ButtonToolbar,
	Button,
	Panel,
	SelectPicker,
	DatePicker,
} from 'rsuite';
import axios from 'axios';

export const ParsePlanCreatePage = () => {
	const baseUrl = '/api/sites/';
	const [sites, setSites] = useState();

	const converSitesForSelect = (sitesArr) => {
		console.log('sitesArr', sitesArr);
		const mappedData = sitesArr.map((item) => ({
			label: item.name,
			value: item._id,
		}));
		console.log('mappedData: ', mappedData);
        setSites(mappedData)

	};

	useEffect(() => {
		axios
			.get(`${baseUrl}/names`)
			.then((res) => {
				converSitesForSelect(res.data);
			})
			.catch((e) => {
				console.log(e);
			});
	}, []);

	return (
		<>
			<Panel bordered={true} shaded={true} header='Parse Plan From'>
				<Form layout='horizontal'>

					<Form.Group controlId='selectPicker'>
						<Form.ControlLabel>Select Site:</Form.ControlLabel>
						<Form.Control name='selectPicker' accepter={SelectPicker} data={[]} />
					</Form.Group>

					<Form.Group controlId='datePicker'>
						<Form.ControlLabel>DatePicker:</Form.ControlLabel>
						<Form.Control name='datePicker' accepter={DatePicker} />
					</Form.Group>

					<Form.Group>
						<ButtonToolbar>
							<Button appearance='primary'>Submit</Button>
							<Button appearance='default'>Cancel</Button>
						</ButtonToolbar>
					</Form.Group>
				</Form>
			</Panel>
		</>
	);
};
