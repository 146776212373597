import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import 'rsuite/dist/rsuite.min.css';

import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import { HomePage } from '../pages/HomePage';
import { ProductListPage } from '../pages/ProductListPage';
import { ParsePlanPage } from '../pages/ParsePlanPage';
import { ParsePlanCreatePage } from '../pages/ParsePlanCreatePage';

export default function App() {
	return (
		<div className='show-fake-browser navbar-page'>
			<Header />
			<Content>
				<Routes>
					<Route path='/parse-plans' element={<ParsePlanPage />} />
					<Route path='/parse-plans/create' element={<ParsePlanCreatePage />} />
					<Route path='/products' element={<ProductListPage />} />
					<Route path='/' element={<HomePage />} />
				</Routes>
			</Content>
			<Footer>Link-or.net</Footer>
		</div>
	);
}
