import React from 'react';
import { Row, Col, Grid } from 'rsuite';
import { HomePageCard } from '../components/pages/HomePage/HomePageCard';

export const HomePage = () => {
	return (
		<>
			<Grid fluid>
				<Row className='show-grid'>
					<Col xs={8}>
						<HomePageCard titleText='Products Page' targetLink='/products' />
					</Col>
					<Col xs={8}>
						<HomePageCard titleText='Parse Plan Page' targetLink='/parse-plans' />
					</Col>
					<Col xs={8}>
						<HomePageCard titleText='Sites Page' targetLink='/sites' />
					</Col>
				</Row>
			</Grid>
		</>
	);
};
