import React, { useEffect, useState } from 'react';
import { Message, Pagination } from 'rsuite';
import axios from 'axios';
import { ProductResultList } from '../components/pages/ProductListPage/ProductResultList'; 

export const ProductListPage = () => {
	const baseUrl = '/api/products';
	const [backendUrl, setBackendUrl] = useState(baseUrl);
	const [productsList, setProductsList] = useState([]);

	//pagintaion
	const [activePage, setActivePage] = React.useState(1);
	const [total, setTotal] = React.useState(0);
	const [limit, setLimit] = React.useState(50);
	const [pages, setPages] = React.useState(1);

	useEffect(() => {
		axios.get(backendUrl).then((res) => {
			const { data, paging } = res.data;
			setProductsList(data);

			//paging setup
			setTotal(paging.total);
			setLimit(paging.limit);
			setPages(paging.pages);
		});
	}, [backendUrl]);

	const changePageHandler = (page) => {
		setActivePage(page);
		let tagetUrl = `${baseUrl}?limit=${limit}&page=${page}`;
		
		setBackendUrl(tagetUrl);
	};
	const changeLimitHandler = (limit) => {
		setLimit(limit);
		let tagetUrl = `${baseUrl}?limit=${limit}&page=${activePage}`;
		setBackendUrl(tagetUrl);
	};

	return (
		<>
			<h5>Products List page</h5>
			<p>
				<small> Display all products </small>
			</p>

			{productsList.length > 0 ? (
				<div>
					<ProductResultList productsList={productsList} />
					<div className=''>
						<Pagination
							prev
							last
							next
							first
							size='md'
							pages={pages}
							activePage={activePage}
							total={total}
							limit={limit}
							onChangePage={changePageHandler}
							onChangeLimit={changeLimitHandler}
						/>
					</div>
				</div>
			) : (
				<Message description='Выберите даты и бренды для построения отчёта' />
			)}
		</>
	);
};
