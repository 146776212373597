import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Panel } from 'rsuite';

import './HomePageCard.css';

export const HomePageCard = ({ titleText, targetLink }) => {
	const [title, setTitle] = useState(titleText);
	const [link, setLink] = useState(targetLink);

	return (
		<div className='home-card'>
			<Link to={link}>
				<Panel
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						minHeight: '230px',
						fontSize: '25px',
						fontWeight: 'bold',
						background: '#25b3f5',
					}}
				>
					{title}
				</Panel>
			</Link>
		</div>
	);
};
