import React from 'react';
import { Header, Navbar, Nav } from 'rsuite';
import { Link } from 'react-router-dom';
import HomeIcon from '@rsuite/icons/legacy/Home';
import CogIcon from '@rsuite/icons/legacy/Cog';
import './style.css';

export default function MyHeader() {
	return (
		<Header>
			<div className='header'>
				<Navbar appearance='inverse'>
					<Nav>
						<Nav.Item as={Link} to={'/'} icon={<HomeIcon />}>
							Home
						</Nav.Item>
						<Nav.Item as={Link} to={'/products'}>
							Products
						</Nav.Item>
						<Nav.Item as={Link} to={'/parse-plans'}>
							Parse Plan
						</Nav.Item>
					</Nav>
					<Nav pullRight>
						<Nav.Item icon={<CogIcon />}>Settings</Nav.Item>
					</Nav>
				</Navbar>
			</div>
		</Header>
	);
}
