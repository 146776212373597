import React from 'react';
import { Content } from 'rsuite';
import './style.css';

export default function MyContent({ children }) {
	return (
		<Content>
			<div className='content'>{children}</div>
		</Content>
	);
}
